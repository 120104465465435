import React from "react"
import { Link } from "gatsby"

const BlogRelatedPosts = ({ data }) => {
  return (
    <div className="related-post-container">
      <article className="related-post-item">
        <div className="related-post-image col-3">
          <Link to={`/blogs/${data.slug}`} className="thumb ">
            <img
              className="realated-post-thumb-img col-12"
              src={data?.frontmatter?.thumbImage?.publicURL}
            />
          </Link>
        </div>

        <div className="related-post-info col-9">
          <time className="related-post-date">{data?.frontmatter?.date}</time>
          <h4 className="related-post-title">
            <Link to={`/blogs/${data.slug}`}>{data?.frontmatter?.title}</Link>
          </h4>
        </div>

        <div className="clear"></div>
      </article>
    </div>
  )
}

export default BlogRelatedPosts
