import React from "react"
import * as Icon from "react-feather"
import { graphql, Link } from "gatsby"
import Footer from "../components/_App/Footer"
import PageBanner from "../components/Common/PageBanner"
import NavbarNew from "../components/NavbarNew"
import SEO from "../components/_App/seo"
import Layout from "../components/_App/layout"
import { MDXRenderer } from "gatsby-plugin-mdx"
import BlogRelatedPosts from "../components/blog-related-post"
import SharePost from "../components/share-post"
import GetInTouch from "../components/get-in-touch"

const BlogDetails = ({ data }) => {
  const blogPageMain = data?.blogPageMain?.nodes[0]?.childMdx
  const slug = blogPageMain?.slug
  const {
    title,
    detailImage,
    date,
    summary,
    thumbImage,
    relatedPosts,
    seoTitle,
    seoDescription,
    keywords,
  } = blogPageMain?.frontmatter
  const blogList = data?.blogList?.nodes

  const relatedBlogList: any = []
  relatedPosts?.forEach((blog: any) => {
    for (let i = 0; i < blogList.length; i++) {
      if (blog === blogList[i].childMdx.slug) {
        relatedBlogList.push(blogList[i])
      }
    }
  })

  return (
    <Layout>
      <SEO
        title={seoTitle}
        description={seoDescription}
        ogImage={thumbImage?.publicURL}
        keywords={keywords}
        url={`/blogs/${slug}`}
        ogTwitterCardType="summary"
      />

      <NavbarNew />

      <PageBanner pageTitle={title} />

      <div className="blog-details-area ptb-50">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="blog-details-desc">
                <div className="article-image">
                  <img src={detailImage?.publicURL} alt="image" />
                </div>

                <div className="article-content">
                  <div className="entry-meta">
                    <ul>
                      <li>
                        <Icon.Clock /> {date}
                      </li>
                    </ul>
                  </div>

                  <section>
                    <h2>{title}</h2>
                    <MDXRenderer>{blogPageMain?.body}</MDXRenderer>
                  </section>
                </div>
              </div>
            </div>

            {data?.blogPageMain?.nodes && data?.blogPageMain?.nodes.length > 0 && (
              <div className="col-lg-4 col-sm-6 col-md-6">
                <div className="widget-area">
                  <div className="widget widget_share_post single-footer-widget">
                    {/* {console.log(
                      "our work",
                      data?.blogPageMain?.nodes[0]?.childMdx?.slug
                    )} */}
                    <SharePost
                      data={`blogs/${data?.blogPageMain?.nodes[0]?.childMdx?.slug}`}
                    />
                  </div>

                  <div className="widget widget_startp_posts_thumb">
                    <h3 className="widget-title">Related Posts</h3>
                  </div>

                  {relatedBlogList
                    .filter((node: any) => node?.childMdx?.slug !== slug)
                    .map((node: any, index: number) => (
                      <BlogRelatedPosts data={node?.childMdx} key={index + 1} />
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <GetInTouch />
      <Footer />
    </Layout>
  )
}

export default BlogDetails
export const query = graphql`
  query blogPageDetails($slug: String) {
    blogPageMain: allFile(
      filter: {
        sourceInstanceName: { eq: "blogDetails" }
        childMdx: { slug: { eq: $slug } }
        name: { eq: "index" }
      }
    ) {
      nodes {
        childMdx {
          frontmatter {
            title
            date
            summary
            seoTitle
            seoDescription
            relatedPosts
            detailImage {
              publicURL
            }
            thumbImage {
              publicURL
            }
          }
          slug
          body
        }
      }
    }
    blogList: allFile(
      filter: {
        sourceInstanceName: { eq: "blogDetails" }
        name: { eq: "index" }
      }
      limit: 4
    ) {
      nodes {
        childMdx {
          frontmatter {
            title
            date
            thumbImage {
              publicURL
            }
            seoTitle
            seoDescription
            keywords
          }
          slug
        }
      }
    }
  }
`
